import React from 'react'
//Types
import { SectionAboutHighlight } from '../../../types/sections'

//Components
import { Box, Button, Flex, Heading, Image, Text } from '../../../atoms'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'

export interface AboutHighlightProps {
  data: SectionAboutHighlight
}

const AboutHighlight: React.FC<AboutHighlightProps> = ({ data }) => {
  const { id, sectionId, topAsset, headline, backgroundColor, highlights, bottomHeadline, button } = data
  return (
    <Box as={'section'} id={sectionId || id} className={'p-section'} position={'relative'} my={[80, 80, 120]}>
      {/* Top */}
      <Box position={'relative'}>
        <Box
          position={'absolute'}
          width={'100%'}
          height={'50%'}
          bottom={0}
          left={0}
          zIndex={1}
          bg={backgroundColor ? backgroundColor.hex : 'transparent'}
        />
        <Box position={'relative'} zIndex={2}>
          <Grid>
            <Row center={'xs'}>
              <Col xs={12} md={8}>
                <Box borderRadius={'regular'} overflow={'hidden'}>
                  <Image
                    draggable={false}
                    alt={topAsset.alt || ''}
                    image={topAsset.gatsbyImageData}
                    style={{ overflow: 'hidden', borderRadius: '16px' }}
                    imgStyle={{ borderRadius: '16px' }}
                  />
                </Box>
              </Col>
            </Row>
          </Grid>
        </Box>
      </Box>

      {/* Content */}
      <Box bg={backgroundColor ? backgroundColor.hex : 'transparent'} pt={[60, 80, 120]} pb={[40, 60, 100]}>
        <Grid>
          <Box mb={[8, 9, 10]}>
            <Row center={'xs'}>
              <Col xs={12} md={8}>
                <Heading as='h4' textAlign={'center'}>
                  {headline}
                </Heading>
              </Col>
            </Row>
          </Box>
          <Row around={'xs'}>
            {highlights.map((h) => {
              return (
                <Col key={h.id} xs={12} sm={4} md={3}>
                  <Box mb={[7, 8, 9]} maxWidth={320} mx={'auto'}>
                    <Box borderRadius={['medium', 'medium', 'large']} overflow={'hidden'}>
                      <Image
                        draggable={false}
                        alt={h.asset.alt || ''}
                        image={h.asset.gatsbyImageData}
                        style={{ overflow: 'hidden', borderRadius: '32px' }}
                        imgStyle={{ borderRadius: '32px' }}
                      />
                    </Box>
                    <Box mt={[4, 5, 6]}>
                      <Text
                        fontFamily={'hoves'}
                        fontSize={['32px', '40px', '56px']}
                        fontWeight={'semi'}
                        lineHeight={['40px', '48px', '64px']}
                        textAlign={'center'}
                      >
                        {h.headline}
                      </Text>
                      <Text textAlign={'center'}>{h.subHeadline}</Text>
                    </Box>
                  </Box>
                </Col>
              )
            })}
          </Row>
        </Grid>
      </Box>

      {/* Bottom */}
      <Box position={'relative'}>
        <Box
          position={'absolute'}
          width={'100%'}
          height={'50%'}
          top={0}
          left={0}
          zIndex={1}
          bg={backgroundColor ? backgroundColor.hex : 'transparent'}
        />
        <Box position={'relative'} zIndex={2}>
          <Grid>
            <Row>
              <Col xs={12}>
                <Box borderRadius={'medium'} p={[7, 9, 10]} bg={'dark'}>
                  <Heading as={'h4'} color={'white'} textAlign={'center'} mb={!!button ? 5 : 0}>
                    {bottomHeadline}
                  </Heading>
                  {!!button && (
                    <Flex justifyContent={'center'}>
                      <Button {...button} />
                    </Flex>
                  )}
                </Box>
              </Col>
            </Row>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default AboutHighlight
